@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


/* Simple spinning loader with Tailwind */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
  border-top-color: #3498db;  /* Change color to match your design */
  animation: spin 1s linear infinite;
}

